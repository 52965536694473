.button1 {
    background-color: var(--croonus-black);
    color: var(--croonus-white);
    padding: 0.5rem 0;
    border-radius: 5px;
}

.line {
    text-decoration: line-through;
}

.button1:hover {
    background-color: var(--croonus-lightRed);
}

.button2 {
    background-color: var(--croonus-black);
    color: var(--croonus-white) !important;
    padding: 0.5rem 0;
    border-radius: 5px;
}

.button2 a {
    color: var(--croonus-white) !important;
    text-decoration: none !important;
}

.button2:hover {
    background-color: var(--croonus-lightRed);
}

.button1:hover {
    background-color: var(--croonus-lightRed);
}

.wrapper {
    transition: box-shadow 340ms;
    padding: 0.5rem;

    form {
        opacity: 0;
        transition: all 340ms;
        background-color: transparent;

        // Allow the "Cart" button to take a bit more space
        div:last-child {
            padding-left: 0;
        }
    }

    .productImage1 {
        display: block;
    }

    .productImage2 {
        display: none;
    }

    &:hover {
        form {
            opacity: 1;
        }

        .wishlist {
            display: flex;
        }

        .productImage1 {
            display: none;
        }

        .productImage2 {
            display: block;
        }
    }
}

.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    color: var(--croonus-black);
    text-decoration: none;
    width: 100%;
    margin: auto;
}

.productImageContainer {
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
}

.productImage {
    width: 80%;
    height: 250px;
}

.imageContainer {
    width: 100%;
    height: 100%;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    height: 100%;
    max-height: 250px;
    width:100%;
    object-fit: cover;
}

.name {
    width: 100%;
    text-align: center;
    margin: 0.25rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    height: 3em;
    color: var(--croonus-black);
    margin-bottom: 0.25rem;
}

.code {
    margin: 0.5rem 0;
    color: var(--croonus-black);

    .codeTitle {
        margin-right: 0.5rem;
    }
}

.category {
    font-size: 0.75rem;
    color: var(--croonus-black);
}

.price {
    display: block;
    color: var(--croonus-white);
    background-color: var(--primary-color);
    filter: brightness(1.1);
    width: 100%;
    border-radius: 5px;
    padding: 0.3rem 0;
    font-weight: 500;
}

.secondPrice,
.noSecondPrice {
    display: block;
    color: var(--croonus-black);
    text-decoration: line-through;
    filter: brightness(1.1);
    width: 100%;
    font-size: 0.85rem;
    border-radius: 5px;
    padding: 0.3rem 0;
    font-weight: 400;
    line-height: normal;
}

.noSecondPrice {
    text-decoration: none;
}

.wishlist {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    padding: 0.5rem;
    display: none;
    border: unset;
    border-radius: 50%;
    background-color: var(--croonus-lightGray);
    transition: all 200ms;

    img {
        transition: all 200ms;
        width: 100%;
    }
}

.mobileCart {
    position: absolute;
    bottom: 0;
    right: calc(50% - 3.2rem);
    width: 2.5rem;
    padding: 0.5rem;
    display: none;
    border: unset;
    border-radius: 2px;
    background-color: var(--croonus-lightGray);
    transition: all 200ms;

    img {
        transition: all 200ms;
        width: 100%;
    }
}

.active {
    background-color: var(--primary-color);
    width: 2.5rem;

    img {
        filter: brightness(0) invert(1);
    }
}

.stickers {
    position: absolute;
    top: 0;
    left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sticker {
    text-decoration: none;
    color: var(--croonus-black);
    transition: all 200ms;
    background-color: var(--primary-color);
    padding: 0.4rem;
    color: white;

    img {
        height: 3rem;
        width: 3rem;
        transition: all 200ms;
    }

    &:hover {
        img {
            height: 7rem;
            width: 7rem;
        }
    }
}

.wishlist:focus,
.mobileCart:focus {
    outline: none;
    border: none;
}

.tcPrice {
    text-decoration: none;
    color: var(--croonus-black);
}

.discounted {
    color: var(--primary-color);
    text-decoration: none;
}

.discountedUnderline {
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.rebateStyle {
    color: var(--croonus-black);
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.discountRabate {
    color: var(--primary-color);
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

@media screen and (min-width: 768px) {
    .wishlist {
        &:hover {
            background-color: var(--primary-color);
            width: 2.7rem;

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        width: 100%;
        padding: 0;

        form {
            display: none;
        }
    }

    .imageContainer,
    .productImage {
        width: 100%;
        height: 280px;
    }

    .image {
        height: 100%;
        max-height: unset;
        width: auto;
    }

    .category {
        font-size: 0.5rem;
    }

    .name {
        font-size: 0.8rem;
    }

    .wishlist {
        top: unset;
        left: calc(50% - 3.2rem);
        padding: 0.5rem;
        border-radius: 0.2rem;
    }

    .mobileCart,
    .wishlist {
        display: flex;
        width: 3rem;
        bottom: 0.1rem;
        height: 1.8rem;
        padding: 0;
        padding: 0.2rem 1rem;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            height: 90%;
            width: auto;
        }
    }

    .price {
        font-size: 0.9rem;
        padding: 0.1rem;
    }

    .stickers {
        left: 0;
    }

    .sticker,
    .sticker:hover {
        img {
            height: 2rem;
            width: 2rem;
        }
    }
}
.wrapper.nopadding {
    padding: 0;
}

.loading {
    position: absolute;
    left: 50%;
    top: 43%;
    transform: translate(-50%, -50%);
}

.loading2 {
    visibility: hidden;
}
